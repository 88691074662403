// Componentes ====================================================
import Navbar from '../Navbar';
import Footer from '../Footer';
// ================================================================
// Resources ======================================================
import './index.css';
// ================================================================

const Container = ({ children,logged }) => {

    // JSX ***********************************************************
    return (
        <>
            <div className="container-navbar">
                <Navbar />
            </div>
            <div className="container-content">
                {children}
            </div>
            <div className="container-footer">
                {logged && <Footer />}
            </div>
        </>
    )
    // *****************************************************************
}

export default Container;
