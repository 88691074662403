/* we are using create react app and need to use REACT_APP_BACKEND environment variable */

const config = {
    //PRODUCTION
    api: process.env.REACT_APP_BACKEND
    //DEVELOPMENT
    //api: 'http://localhost'

    //DEVELOPMENTVERCEL
    //api: 'https://central-hipotecaria-backend-git-2fase-cambios-jdx-team.vercel.app'
}


export default config;