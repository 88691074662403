import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ItemMenu from "../ItemMenu";
import ProposalsFilter from "../ProposalsFilterV2";
import { Box, Chip, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
const ProposalsListRealState = ({ proposalsRealState }) => {
  const { states, totalAmountCompraventa, totalAmountCesion } = useContext(AppContext);

  function parseText(string) {
    let resultado = "";
    for (let i = 0; i < string.length; i++) {
      if (string[i] === "@") {
        resultado += "<br/>";
      } else {
        resultado += string[i];
      }
    }
    return resultado.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ mb: 5, width: { xs: "100%", lg: "90%" } }}
      >
        <ProposalsFilter />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nº PROPUESTA</TableCell>
              {/* COLOCAR UN SALTO DE LINEA LUEGO DE FECHA ENTRADA/ */}
              <TableCell align="center">
                FECHA ENTRADA <br />
                FECHA FIRMA
              </TableCell>
              <TableCell align="center">
                NOMBRE SOLICITANTES <br /> DIRECCIÓN INMUEBLE A HIPOTECAR
              </TableCell>
              <TableCell align="center">TIPO DE OPERACIÓN</TableCell>
              <TableCell align="center">
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "normal",
                  }}>

                  <span>
                    ESTADO
                    <Button variant="text" size="small" sx={{}} onClick={() => {
                      window.open("https://centralhipotecaria.com/wp-content/uploads/2023/07/Definicion-ESTADOS.pdf")
                    }}>
                      info
                    </Button>
                  </span>
                </Container>

              </TableCell>
              <TableCell align="center">IMPORTE DE COMPRAVENTA</TableCell>
              <TableCell align="center">CESIÓN</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!proposalsRealState.length && (
              <Box sx={{ m: 4 }}>
                <h4>No hay propuestas para mostrar</h4>
              </Box>
            )}


            {proposalsRealState
              .map((proposal, index) => {
                return (
                  <TableRow
                    key={proposal.fileNum + "" + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {proposal.fileNum} <br />
                      <b>{proposal.aditionalText}</b>
                    </TableCell>
                    <TableCell align="center">
                      {proposal.entryDate &&
                        `${proposal.entryDate}`}
                      <br />
                      {proposal.signDate && (
                        <b>{proposal.signDate} </b>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: parseText(proposal?.owner),
                          }}
                          key={index}
                        ></div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{proposal.opType}</TableCell>
                    <TableCell align="center" >
                      <Container
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                          alignItems: "center",
                        }}>
                        <Chip
                          size="small"
                          label={proposal?.currentState || "NO STATE"}
                          color={"warning"}
                          style={{
                            backgroundColor: states.find(
                              ({ name }) =>
                                name.toLowerCase() ===
                                proposal?.currentState?.toLowerCase()
                            )?.color,
                          }}
                        />
                      </Container>
                    </TableCell>{" "}
                    {/* PENDIENTE ESTADO EN OBJETO */}
                    <TableCell align="center">{proposal.amountNew}</TableCell>
                    <TableCell align="center">{proposal.percentage}</TableCell>
                    <TableCell align="right">
                      {
                        <>
                          <ItemMenu
                            fileNum={proposal.fileNum}
                            linkRedirect={proposal?.paymentMethodLink}
                            documentsEnabled={proposal?.documentsEnabled}
                          />{" "}
                        </>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer >
      <Grid container spacing={3} alignSelf={"flex-end"} justifyContent={"flex-end"} marginRight={15}>
        <Stack spacing={3} display={"block"}>
          <TextField
            id="outlined-basic"
            label="Total Compraventa"
            variant="standard"
            fullWidth
            value={totalAmountCompraventa}
            inputMode="numeric"
          />
          <TextField
            id="outlined-basic"
            label="Total Cesión"
            variant="standard"
            fullWidth
            value={totalAmountCesion}
            inputMode="numeric"
          />
        </Stack>
      </Grid>
    </>
  );
};

export default ProposalsListRealState;
