// Hooks =====================================================
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
//import { useState } from "react";
import { Link } from "react-router-dom";
// ================================================================
// Context ========================================================
import { useContext } from 'react';
import { AppContext } from '../../context/appContext';
// ================================================================
import { FileUploader } from "../FileUploader";
// Import the main component
// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import useMergePdf from '../../hooks/useMergePdf';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// Resources =====================================================
import './index.css';

import { Row } from '../Row';
import { ArrowBack, Send } from "@mui/icons-material";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import customAxiosInstance from "../../services/customAxiosInstance";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SelectedDocument = () => {
    //State ******************************************************
    const navigate = useNavigate();
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [documentUploaded, setDocumentUploaded] = useState(null)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingDocument, setLoadingDocument] = useState(false)
    const [error, setError] = useState("")
    const { handleGeneratePdf } = useMergePdf();
    // *************************************************************
    // Context ****************************************************
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { userData, currentDocument, setCurrentDocument } = useContext(AppContext);
    // *************************************************************
    // Functions **************************************************


    useEffect(() => {
        if (currentDocument && currentDocument.status !== "notcompleted") {
            (async () => {
                try {
                    setLoadingDocument(true)
                    //const { data } = await customAxiosInstance().post("/getQuestionDocument/1200/402", {},
                    const { data } = await customAxiosInstance().post(`/getQuestionDocument/${userData.fileNum}/${currentDocument.proposalId}`, {},
                        {
                            responseType: "arraybuffer",
                            responseEncoding: "binary",
                        })
                    const blob = new Blob([data], { type: "application/pdf" });
                    const objectURL = URL.createObjectURL(blob);
                    setDocumentUploaded(objectURL);
                    setLoadingDocument(false)
                } catch (err) {
                    setLoading(false)
                    setError("Error al obtener documento, por favor, vuelve atrás e intentalo nuevamente.");

                }
            })();
        }
    }, [currentDocument, userData.fileNum])

    const handleDeleteItem = (indexFile) => {
        setFilesToUpload(filesToUpload.filter((file, index) => (indexFile !== index) ? file : null));
    }

    const handleBackClick = () => {
        filesToUpload.length
            ? handleClickOpen()
            : handleBack()
    }

    const handleBack = () => {
        setCurrentDocument(null);
        navigate("/documents")
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUploadFiles = async () => {
        try {
            setLoading(true)
            const formData = new FormData(); // 

            const pdfBlob = await handleGeneratePdf(filesToUpload);

            formData.append("file", pdfBlob);

            await customAxiosInstance().post(`/uploadFiles/${userData.fileNum}/${currentDocument.proposalId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "arraybuffer",
                    responseEncoding: "binary",
                }
            );


            const objectURL = URL.createObjectURL(pdfBlob);

            await customAxiosInstance().post(`/database/changeStatusOfFile/${userData.fileNum}/${currentDocument.proposalId}/pending`)

            setDocumentUploaded(objectURL);
            setFilesToUpload([])
            setLoading(false)

            const tempCurrentDocument = { ...currentDocument }
            tempCurrentDocument.status = "pending"
            setCurrentDocument(tempCurrentDocument)
            setError(false)

        } catch (err) {
            setLoading(false)
            setError("Error al subir archivos, por favor, vuelve atrás e intentalo nuevamente.");
            setTimeout(() => setError(false), 10000);
        }

    }


    // *************************************************************
    if (!currentDocument) {
        navigate("/documents")
        return null
    }

    return (
        <>
            <div className="container-selectedDocument">
                <div className="card-selectedDocument">
                    <div className="return_button">
                        <button className="button-back"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleBackClick()}>
                            <ArrowBack sx={{ color: "white" }} style={{ margin: "2px 2px 0px 2px" }} />
                        </button>
                        <Link to="/Documents">

                        </Link>
                    </div>
                    <div>
                        <div className="line-info">
                            <h2>Estado </h2>
                            <StatusLabel sectionStatus={currentDocument.status} />
                        </div>
                        <div className="line-info">
                            <h3>Nombre </h3><h3 style={{ color: "#034385", textAlign: "right" }}>{currentDocument.fileName}</h3>
                        </div>
                        {filesToUpload && filesToUpload.length > 0 && (
                            <div>
                                <ul>
                                </ul>
                            </div>
                        )}
                    </div>

                    {!loadingDocument && <div className="uploader-container">
                        {documentUploaded ? null : <FileUploader filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} error={error} setError={setError} />}
                        {filesToUpload && filesToUpload.map((file, index) => (
                            <Row
                                backgroundColor="#005798"
                                title={file.name}
                                size="large"
                                deleteButton
                                handleDeleteButton={() => handleDeleteItem(index)}
                                key={index}>
                            </Row>
                        ))}

                        <div style={{ textAlign: "center" }}>
                            {filesToUpload.length > 0 && <span style={{ marginBottom: "10px", color: "#01b00e" }}>Archivo añadido correctamente, puedes adjuntar más documentos</span>}
                        </div>
                        {/* {fileLarge && <span style={{ marginBottom: "10px", color: "red" }}>Archivo demasiado grande, máximo 2MB</span>} */}

                        {!error && documentUploaded && <div
                            style={{
                                marginTop: "50px",
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                height: '750px',
                            }}
                        >

                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                <Viewer
                                    fileUrl={documentUploaded}
                                    plugins={[
                                        // Register plugins
                                        defaultLayoutPluginInstance,
                                    ]}
                                />
                            </Worker>

                        </div>}

                        {!documentUploaded &&
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse"
                                }}>
                                <LoadingButton
                                    disabled={!filesToUpload.length}
                                    size="small"
                                    onClick={() => handleUploadFiles()}
                                    endIcon={<Send />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Subir documentos
                                </LoadingButton>
                            </div>}
                    </div>}
                    {error && <Alert severity="error">{error}</Alert>}
                </div>
            </div>

            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"¿Estás seguro que quieres ir atrás sin guardar los documentos?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error">Cancelar</Button>
                        <Button onClick={handleBack}>Aceptar</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

const StatusLabel = ({ sectionStatus }) => {

    const status = {
        notcompleted: {
            text: "Pendiente",
            color: "error"
        },
        pending: {
            text: "En revisión",
            color: "warning"
        },
        completed: {
            text: "Completado",
            color: "success"
        }
    }
    if (!status[sectionStatus]) return null

    return (
        <Chip
            label={status[sectionStatus].text} color={status[sectionStatus].color}
        />
    )
}
