import { File } from "buffer";
import { useState } from "react";
const { PDFDocument } = require("pdf-lib");

const useMergePdf = () => {
  const [files, setFiles] = useState([]);

  const handleUploadImages = (files) => {

    const filesToUpload = [];

    Array.from(files).forEach((file) => {
      filesToUpload.push(file);
    });

    const tempFiles = [...files, ...filesToUpload];
    setFiles(tempFiles);
  };


  const handleGeneratePdf = async (files) => {
    const mergedDoc = await PDFDocument.create();

    for (const file of files) {
      const fileType = file.type.split("/")[1].toUpperCase();

      if (fileType === "JPEG" || fileType === "JPG" || fileType === "PNG") {
        const imageData = await file.arrayBuffer();
        let image;

        if (fileType === "JPEG" || fileType === "JPG") {
          image = await mergedDoc.embedJpg(imageData);
        } else if (fileType === "PNG") {
          image = await mergedDoc.embedPng(imageData);
        }

        const page = mergedDoc.addPage();

        // Obtener las dimensiones de la página
        const { width, height } = page.getSize();

        // Obtener las dimensiones de la imagen
        const imageWidth = image.width;
        const imageHeight = image.height;

        // Calcular el factor de escala para ajustar la imagen
        const scaleFactor = Math.min(width / imageWidth, height / imageHeight);

        // Calcular las dimensiones ajustadas de la imagen
        const adjustedWidth = imageWidth * scaleFactor;
        const adjustedHeight = imageHeight * scaleFactor;

        // Calcular las coordenadas para centrar la imagen en la página
        const x = (width - adjustedWidth) / 2;
        const y = (height - adjustedHeight) / 2;

        // Dibujar la imagen en la página con las dimensiones ajustadas
        page.drawImage(image, {
          x,
          y,
          width: adjustedWidth,
          height: adjustedHeight
        });
      }

    }

    const pdfFiles = files.filter(({ type }) => type.includes("pdf"));

    for (const file of pdfFiles) {
      const pdfData = await file.arrayBuffer();
      const externalPdf = await PDFDocument.load(pdfData);
      const pages = await mergedDoc.copyPages(
        externalPdf,
        externalPdf.getPageIndices()
      );

      for (const page of pages) {
        mergedDoc.addPage(page);
      }
    }

    const finalBlob = await mergedDoc.save();
    const blob = new Blob([finalBlob], { type: "application/pdf" });

    return blob;

  };

  return { files, handleUploadImages, handleGeneratePdf };
}
export default useMergePdf

