// Hooks ==========================================================
import { useEffect, useState } from "react";
// ================================================================

// Resources ======================================================
import './index.css';
import { ArrowBack } from "@mui/icons-material";
// ================================================================

// Context ========================================================
import { useContext } from 'react';
import { AppContext } from '../../context/appContext';
// ================================================================

// Components =====================================================
import { DocumentsMenu } from "../DocumentsMenu";
import Modal from '../Modal';
// ================================================================

// Utils ==========================================================
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
// ================================================================

const Documents = () => {
    // State *********************************************************
    const navigate = useNavigate();
    const [filesGroupByOwner, setFilesGroupByOwner] = useState(null)
    const {
        selectedMenu,
        setSelectedMenu,
        appFiles,
        currentDocument,
        setCurrentDocument,
        estadoModalUpload,
        setEstadoModalUpload,
        getAppFiles,
        userData,
        isRealState
    } = useContext(AppContext);

    // ***************************************************************

    // Functions *****************************************************

    useEffect(() => {
        userData.fileNum && getAppFiles(userData.fileNum, isRealState);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDocument])

    const refactorizeObject = () => {

        if (appFiles && selectedMenu) {
            const currentSection = appFiles[selectedMenu]

            if (currentSection && currentSection.length) {
                const objByOwner = currentSection.reduce((r, a) => {
                    r[a.owner] = r[a.owner] || [];
                    r[a.owner].push(a);
                    return r;
                }, Object.create(null))
                setFilesGroupByOwner(objByOwner)
            } else {
                setFilesGroupByOwner(null);
            }
        }

    }

    useEffect(() => {
        refactorizeObject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appFiles, selectedMenu])


    const handleButton = (section) => {
        setCurrentDocument(section);
        navigate('/SelectedDocument');
    };


    const SectionCard = ({ sections, owner }) => {
        return (
            <Card sx={{ maxWidth: 1000 }} style={{ marginBottom: "20px" }}  >
                {/* <CardHeader title={owner} /> */}
                <CardContent style={{ padding: "0" }}>

                    <Stack spacing={1}>
                        <SectionOwnerName name={owner} />
                        {sections.map((section, index) => (
                            <div key={index + Math.random()}>
                                {
                                    section.elementType === "title" &&
                                    <SectionTitle key={index + Math.random()} section={section} />
                                }

                                {section.elementType === "question" &&
                                    <SectionQuestion key={index + Math.random()} section={section} />
                                }

                            </div>
                        ))}
                    </Stack>

                </CardContent>
            </Card>
        )
    }

    const SectionOwnerName = ({ name }) => {
        return (
            <div style={{ backgroundColor: "rgb(0 52 91)", padding: "5px" }}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold', textTransform: 'uppercase', textAlign: "center" }} color="white" >
                    {name}
                </Typography>

            </div>
        )
    }

    const SectionTitle = ({ section, index }) => {
        return (
            //<div style={{ backgroundColor: "#005798", padding: "10px" }}>
            <div key={index} className="title" >
                <Typography sx={{ fontSize: 12 }} color="black" >
                    {section.fileName}
                </Typography>

            </div>
        )

    }

    const SectionQuestion = ({ section }) => {
        return (
            <div style={{ padding: "40px 30px", position: "relative", borderBottom: "solid", borderBottomWidth: "thin" }}>

                <Typography sx={{ fontSize: 17 }} >
                    {section.fileName}
                </Typography>

                <StatusLabel sectionStatus={section.status} />

                {<CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={() => handleButton(section)}>
                        {section.status === "notcompleted" ? "Subir documentos" : "Ver archivos"}
                    </Button>
                </CardActions>}

            </div>
        )

    }

    const StatusLabel = ({ sectionStatus }) => {

        const status = {
            notcompleted: {
                text: "Pendiente",
                color: "error"
            },
            pending: {
                text: "En revisión",
                color: "warning"
            },
            completed: {
                text: "Completado",
                color: "success"
            }
        }
        if (!status[sectionStatus]) return null

        return (
            <Chip
                style={{ top: "0", right: "35px", position: "absolute" }}
                label={status[sectionStatus].text} color={status[sectionStatus].color}
            />
        )
    }
    // ***************************************************************





    // JSX ***********************************************************
    return (
        <>
            {/* Modal */}

            <Modal
                estado={estadoModalUpload}
                cambiarEstado={setEstadoModalUpload}
                titulo="Login Correcto"
                mostrarHeader={false}
                posicionModal={'center'}
                padding={'20px'} >

            </Modal>
            <DocumentsMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
            {appFiles
                ? <div className="container-files">
                    {/*  {appFiles ? renderFiles() : null} */}
                    <Link to="/">
                        <button className="button-back" style={{ cursor: "pointer" }}>
                            <ArrowBack sx={{ color: "white" }} style={{ margin: "2px 2px 0px 2px" }} />
                        </button>
                    </Link>
                    {filesGroupByOwner ?
                        Object.keys(filesGroupByOwner).map((ownerSections, index) =>
                            <SectionCard key={index + Math.random()} sections={filesGroupByOwner[ownerSections]} owner={ownerSections} />)
                        :

                        <div style={{ textAlign: "center", marginTop: "50px" }}>
                            <Typography sx={{ fontSize: 20 }}>
                                No hay documentos pendientes de subir
                            </Typography>
                        </div>


                    }

                </div>
                :
                <Box
                    sx={{ display: 'flex', justifyContent: "center" }}
                    style={{ marginTop: "100px" }}
                >
                    <CircularProgress />
                </Box>

            }

        </>
    )
    // ***************************************************************

}

export default Documents;