import React, { useContext, useMemo, useState } from 'react';
import {
    Container,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Grid,
    Button,
    Menu,
    Box,
    Autocomplete
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ManageSearchOutlined, MoreTimeOutlined, RestartAltOutlined } from '@mui/icons-material';
import { subMonths, startOfDay, startOfMonth, endOfMonth, subQuarters, subYears, subWeeks, startOfYear, startOfQuarter } from 'date-fns';
import { AppContext } from '../../context/appContext';


const typeDates = [
    { key: 0, value: 'Entrada' },
    { key: 1, value: 'Firmada' }
];

const dateRanges = [
    { key: '7days', value: 'Últimos 7 días' },
    { key: '14days', value: 'Últimos 14 días' },
    { key: '30days', value: 'Últimos 30 días' },
    { key: '90days', value: 'Últimos 90 días' },
    { key: '12months', value: 'Últimos 12 meses' },
    { key: 'currentMonth', value: 'Mes en curso' },
    { key: 'previousMonth', value: 'Mes anterior' },
    { key: 'currentQuarter', value: 'Trimestre en curso' },
    { key: 'previousQuarter', value: 'Trimestre anterior' },
    { key: 'currentSemester', value: 'Semestre en curso' },
    { key: 'previousSemester', value: 'Semestre anterior' },
    { key: 'currentYear', value: 'Año en curso' },
    { key: 'previousYear', value: 'Año anterior' }
];

const startOfSemester = (date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    return month < 6 ? new Date(year, 0, 1) : new Date(year, 6, 1);
};

const DEFAULT_FILTER = {
    fileNum: null,
    initDate: null,
    endDate: null,
    typeDate: 0,
    state: [],
    address: '',
    ownerName: '',
    collaboratorManager: [],
};

const ProposalsFilter = () => {
    const { proposalsRealState, handleFilterProposals, states } =
        useContext(AppContext);

    const proposalsFileNum = useMemo(() => {
        return proposalsRealState.map((proposal) => proposal.fileNum.toString());
    }, [proposalsRealState]);

    const collaboratorManagers = useMemo(() => {
        const aditionalTexts = proposalsRealState
            .map(proposal => proposal?.aditionalText?.trim())
            .filter(text => !text?.lenght);
        const distinctAditionalTexts = [...new Set(aditionalTexts)];
        return distinctAditionalTexts;
    }, [proposalsRealState]);

    const [filter, setFilter] = useState(DEFAULT_FILTER);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const setDateRange = (range) => {
        const today = startOfDay(new Date());
        switch (range) {
            case '7days':
                setFilter({
                    ...filter,
                    initDate: subWeeks(today, 1),
                    endDate: today,
                });
                break;
            case '14days':
                setFilter({
                    ...filter,
                    initDate: subWeeks(today, 2),
                    endDate: today,
                });
                break;
            case '30days':
                setFilter({
                    ...filter,
                    initDate: subMonths(today, 1),
                    endDate: today,
                });
                break;
            case '90days':
                setFilter({
                    ...filter,
                    initDate: subMonths(today, 3),
                    endDate: today,
                });
                break;
            case '12months':
                setFilter({
                    ...filter,
                    initDate: subMonths(today, 12),
                    endDate: today,
                });
                break;
            case 'currentMonth':
                setFilter({
                    ...filter,
                    initDate: startOfMonth(today),
                    endDate: endOfMonth(today),
                });
                break;
            case 'previousMonth':
                setFilter({
                    ...filter,
                    initDate: startOfMonth(subMonths(today, 1)),
                    endDate: endOfMonth(subMonths(today, 1)),
                });
                break;
            case 'currentQuarter':
                setFilter({
                    ...filter,
                    initDate: startOfQuarter(today),
                    endDate: today,
                });
                break;
            case 'previousQuarter':
                setFilter({
                    ...filter,
                    initDate: startOfQuarter(subQuarters(today, 1)),
                    endDate: endOfMonth(subMonths(today, 1)),
                });
                break;
            case 'currentSemester':
                setFilter({
                    ...filter,
                    initDate: startOfSemester(today),
                    endDate: today,
                });
                break;
            case 'previousSemester':
                setFilter({
                    ...filter,
                    initDate: startOfSemester(subMonths(today, 6)),
                    endDate: endOfMonth(subMonths(today, 6)),
                });
                break;
            case 'currentYear':
                setFilter({
                    ...filter,
                    initDate: startOfYear(today),
                    endDate: today,
                });
                break;
            case 'previousYear':
                setFilter({
                    ...filter,
                    initDate: startOfYear(subYears(today, 1)),
                    endDate: endOfMonth(subMonths(today, 12)),
                });
                break;
            default:
                setFilter({
                    ...filter,
                    initDate: null,
                    endDate: null,
                });
        }
        handleCloseMenu();
    };

    const restartFilter = () => {
        setFilter(DEFAULT_FILTER);
        handleFilterProposals(DEFAULT_FILTER);
    };

    const applyFilter = () => {
        handleFilterProposals(filter);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth={"xl"}>
                <Grid container spacing={1} mt={2} mb={3}>
                    <Grid item xs={12} sm={1.25}>
                        <Autocomplete
                            options={proposalsFileNum}
                            value={filter.fileNum}
                            onChange={(event, newValue) => handleFilterChange({ target: { name: 'fileNum', value: newValue } })}
                            renderInput={(params) => <TextField {...params} label="Nº propuesta" />}
                            isOptionEqualToValue={(option, value) => option === value || value === null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Box display="flex" alignItems="center" width="100%">
                                <DesktopDatePicker
                                    label="Fecha inicio"
                                    value={filter.initDate}
                                    onChange={(date) => handleFilterChange({ target: { name: 'initDate', value: date } })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    format="dd/MM/yyyy"
                                />
                                <DesktopDatePicker
                                    label="Fecha fin"
                                    value={filter.endDate}
                                    onChange={(date) => handleFilterChange({ target: { name: 'endDate', value: date } })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    format="dd/MM/yyyy"
                                />
                                <Button
                                    onClick={handleOpenMenu}
                                    variant="contained"
                                    color="primary"
                                    sx={{ height: 56, minWidth: 0 }}
                                >
                                    <MoreTimeOutlined />
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    {dateRanges.map((dateRange) => (
                                        <MenuItem onClick={() => setDateRange(dateRange.key)}>{dateRange.value}</MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="Tipo de fecha"
                                        name="typeDate"
                                        value={filter.typeDate}
                                        onChange={handleFilterChange}
                                        row
                                    >
                                        {typeDates.map((typeDate) => (
                                            <FormControlLabel value={typeDate.key} control={<Radio />} label={typeDate.value} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1.763}>
                        <FormControl fullWidth>
                            <InputLabel>Estado</InputLabel>
                            <Select
                                multiple
                                name="state"
                                value={filter.state}
                                onChange={handleFilterChange}
                            >
                                {states.map((state) => (
                                    <MenuItem key={state.proposalStateId} value={state.name}>
                                        {state.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={1.65}>
                        <TextField
                            label="Dirección"
                            name="address"
                            value={filter.address}
                            onChange={handleFilterChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={1.65}>
                        <TextField
                            label="Nombre de Solicitante"
                            name="ownerName"
                            value={filter.ownerName}
                            onChange={handleFilterChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={1.65}>
                        <FormControl fullWidth>
                            <InputLabel>Colaborador</InputLabel>
                            <Select
                                multiple
                                name="collaboratorManager"
                                value={filter.collaboratorManager}
                                onChange={handleFilterChange}
                            >
                                {collaboratorManagers.map((collaboratorManager) => (
                                    <MenuItem key={collaboratorManager} value={collaboratorManager}>
                                        {collaboratorManager}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={1.037}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ height: 56, minWidth: 0, marginRight: 1 }}
                            onClick={restartFilter}
                        >
                            <RestartAltOutlined />
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ height: 56, minWidth: 0 }}
                            onClick={applyFilter}
                        >
                            <ManageSearchOutlined />
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </LocalizationProvider>
    );
};

export default ProposalsFilter;
