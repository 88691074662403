// Hooks ==========================================================
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
// ================================================================

// Components =====================================================
import Spinner from "../Spinner";
import LoginShared from "../LoginShared";
// ================================================================

// Resources ======================================================
import "./index.css";
import config from "../../config";
// ================================================================

// Utils ==========================================================
import axios from "axios";
// ================================================================

const LoginRealState = (props) => {
  // State ***********************************************************
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [codeChangePassword, setCodeChangePassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    setAdminData,
    setUserData,
    setIsRealState,
    getProposalsRealState,
    getProposalStates,
    setRealStateLink,
    setRealStateLogo,
    setNewProposalLink,
  } = useContext(AppContext);
  const navigate = useNavigate();
  // *****************************************************************

  useEffect(() => {
    try {
      const chUserDataItem = localStorage.getItem("chUserData")
      const chUserData = chUserDataItem ? JSON.parse(chUserDataItem) : null;
      if (chUserData?.isRealState && chUserData?.userData && chUserData?.adminData) {
        applyLogin(chUserData.userData, chUserData.adminData)
      } else {
        localStorage.clear();
      }
    } catch (err) {
      localStorage.clear();
    }
  }, []);

  // Functions *******************************************************
  const handleLogin = async () => {
    try {
      localStorage.removeItem("ch_user_token");
      setLoading(true);
      setError(false);

      const loginRequest = await axios.post(`${config.api}/login`, {
        username,
        password,
        type: "collaborator",
      });

      if (loginRequest?.data?.changePassword && loginRequest?.data?.email) {
        setUsername(loginRequest.data.email);
        setPassword(null);
        setCodeChangePassword(null);
        setNewPassword(null);
        setForgotPassword(true);
        setChangePassword(true);
        setLoading(false);
        return;
      }

      const userData = loginRequest.data.collaboratorData;
      const adminData = loginRequest.data.collaboratorData;
      const token = loginRequest.data.token;

      localStorage.setItem("ch_user_token", token);
      localStorage.setItem("chUserData",
        JSON.stringify({
          isRealState: true,
          userData,
          adminData,
        })
      );

      applyLogin(userData, adminData, token)
    } catch (err) {
      setTimeout(() => setError(false), 5000);
      setError(true);
      setLoading(false);
    }
  };

  const applyLogin = (userData, adminData) => {

    getProposalsRealState(userData.realstateId);

    getProposalStates();

    setUserData(userData);
    setAdminData(adminData);

    setIsRealState(true);
    setRealStateLink(userData.pdfUrl);
    setRealStateLogo(userData.logoUrl);
    setNewProposalLink(adminData.newProposalLink);

    setLoading(false);
    props.setLogged(true);
    /* getAppFiles(userData.fileNum); */
    navigate("/");
  };
  // *****************************************************************

  // JSX *************************************************************
  return (
    <>
      <div className="login-form_realState">
        {!loading ? (
          <LoginShared
            formTypeTitle="Colaborador"
            formTypeSuffixClassName="realState"
            labelUsername="Usuario"
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            codeChangePassword={codeChangePassword}
            setCodeChangePassword={setCodeChangePassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
            changePassword={changePassword}
            setChangePassword={setChangePassword}
            passwordReset={passwordReset}
            setPasswordReset={setPasswordReset}
            loading={loading}
            setLoading={setLoading}
            error={error}
            setError={setError}
            onSubmit={handleLogin}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
  // *****************************************************************
};

export default LoginRealState;
