// Context ========================================================
// import { useContext } from 'react';
// import { AppContext } from '../../context/appContext';
// ================================================================
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from 'react';
import './style.css';

const inputHoverStyle = {
    color: "#000000",
    backgroundColor: "aliceblue"
}

export const FileUploader = ({ filesToUpload, setFilesToUpload, setError }) => {

    const [hover, setHover] = useState(false)

    const AcceptedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif','application/pdf'];
    const onInputChange = (e) => {
        const files = e.target.files;
        let flag_error=false;
        for (let i = 0; i < files.length; i++) {
            if (!AcceptedTypes.includes(files[i].type)) {
                setError("Uno o varios de los archivos no es una imagen o un pdf, por favor seleccione una imagen o un pdf.");
                setTimeout(() => {setError(false)}, 10000);
                flag_error=true;
            }
        }
        if (!flag_error && files.length > 0) {
            const oldFiles = filesToUpload ? filesToUpload : []
            let files = [...oldFiles];

            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files.item(i);
                files.push(file)
            }
            setFilesToUpload(files)
        }
    };



    return (
        <>
            <div className='drop-zone-container'>
                <div className='drop-zone' style={hover ? inputHoverStyle : {}}>
                    <span className='drop-zone-text'>
                        <CloudUploadIcon />
                        Arrastra y suelta los archivos o haz click aquí
                    </span>
                    <input
                        onDragEnter={() => setHover(true)}
                        onDragLeave={() => setHover(false)}
                        onDrop={() => setHover(false)}
                        type='file'
                        className='drop-zone-input'
                        onChange={onInputChange}
                        accept=".png, .jpeg, .jpg, .pdf"
                        multiple
                    >
                    </input>
                </div>
            </div>


        </>
    )
};