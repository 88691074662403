// Hooks =====================================================
import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useContext } from "react";
import { AppContext } from "../../context/appContext";
// ================================================================

// Components =====================================================
// ================================================================

export const DocumentsMenu = ({ selectedMenu, setSelectedMenu }) => {
  // State *********************************************************

  const { isRealState } = useContext(AppContext);

  const [value, setValue] = useState('cliente');

  useEffect(() => {
    isRealState && setSelectedMenu("vg");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRealState]);

  useEffect(() => {
    selectedMenu && handleChange(null, selectedMenu);
  }, [selectedMenu]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
        sx={{ width: "100%" }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          {!isRealState && (
            <Tab
              value="cliente"
              label="Solicitantes"
              onClick={() => setSelectedMenu("cliente")}
            />
          )}

          {!isRealState && (
            <Tab
              value="vp"
              label="Inmuebles en propiedad"
              onClick={() => setSelectedMenu("vp")}
            />
          )}

          {!isRealState && (
            <Tab
              value="carga"
              label="Cargas"
              onClick={() => setSelectedMenu("carga")}
            />
          )}

          <Tab
            value="vg"
            label="Garantias a hipotecar"
            onClick={() => setSelectedMenu("vg")}
          />
        </Tabs>
      </Box>
    </>
  );
};
