import React from "react";
import "./index.css";
export const SendButton = ({ backgroundColor, disabled, text, textColor,handleButton }) => {
 

    return (
        <div
            onClick={() => handleButton()}
             style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
            className={`sendButton ${disabled ? 'disabled' : null}`}>
            <span style={{ color: textColor }}>
                {text}
            </span>
        </div>


    )
}