import React from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import axios from "axios";
import config from "../../config";

const LoginShared = ({
  formTypeTitle,
  formTypeSuffixClassName,
  labelUsername,
  username,
  setUsername,
  password,
  setPassword,
  codeChangePassword,
  setCodeChangePassword,
  newPassword,
  setNewPassword,
  forgotPassword,
  setForgotPassword,
  changePassword,
  setChangePassword,
  passwordReset,
  setPasswordReset,
  loading,
  setLoading,
  error,
  setError,
  onSubmit,
}) => {
  const headerClass = `login-form-header_${formTypeSuffixClassName}`;
  const groupClass = `form-group_${formTypeSuffixClassName}`;
  const labelClass = `form-label_${formTypeSuffixClassName}`;
  const inputClass = `input_login_${formTypeSuffixClassName}`;
  const buttonClass = `login_button_${formTypeSuffixClassName}`;

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      setError(false);
      setPassword(null);
      setNewPassword(null);
      setCodeChangePassword(null);

      const loginRequest = await axios.post(
        `${config.api}/login/resetPassword`,
        {
          email: username,
        }
      );
      if (loginRequest?.data?.success) {
        setChangePassword(true);
      } else {
        setError(true);
      }
    } catch (err) {
      handleRequestError();
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    try {
      setLoading(true);
      setError(false);

      if (newPassword !== password) {
        setError(true);
      } else {
        const loginRequest = await axios.put(
          `${config.api}/login/changePassword`,
          {
            code: codeChangePassword,
            email: username,
            newPassword: password,
          }
        );
        if (loginRequest?.data?.success) {
          setChangePassword(false);
          setForgotPassword(false);
          setPasswordReset(true);
          setTimeout(() => setPasswordReset(false), 5000);
        } else {
          setError(true);
        }
      }
    } catch (err) {
      handleRequestError();
    } finally {
      setLoading(false);
    }
  };

  const handleRequestError = () => {
    setTimeout(() => setError(false), 5000);
    setError(true);
    setLoading(false);
  };

  const renderLoginForm = () => {
    return (
      <form>
        <div className={headerClass}>
          <h1>{formTypeTitle}</h1>
          {passwordReset && (
            <h5 style={{ color: "green" }}>Contraseña Restablecida</h5>
          )}
        </div>
        <div className={groupClass}>
          <label className={labelClass} htmlFor="username">
            {labelUsername}
          </label>
          <br />
          <input
            type="text"
            className={inputClass}
            id="Usuario"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e?.target?.value)}
          />
        </div>
        <div className={groupClass}>
          <label className={labelClass} htmlFor="password">
            Contraseña
          </label>
          <br />
          <input
            type="password"
            className={inputClass}
            id="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e?.target?.value)}
          />
        </div>
        <button
          id="login_button"
          onClick={onSubmit}
          className={`${buttonClass} btn`}
        >
          Iniciar Sesión
        </button>
        <button
          onClick={() => setForgotPassword(true)}
          className="forgot_button_user btn_user"
        >
          ¿HAS OLVIDADO TU CONTRASEÑA?
        </button>
        {error && (
          <div style={{ color: "red" }}>Usuario o contraseña incorrectos</div>
        )}
      </form>
    );
  };

  const renderForgotPasswordForm = () => {
    return (
      <form>
        <div className={headerClass}>
          <h1>{formTypeTitle}</h1>
          <h5>Restablecer su contraseña</h5>
        </div>
        <div className={groupClass}>
          <label className={labelClass} htmlFor="username">
            Email
          </label>
          <br />
          <input
            type="text"
            className={inputClass}
            id="username"
            placeholder="Email"
            value={username}
            onChange={(e) => setUsername(e?.target?.value)}
          />
        </div>
        <button
          id="login_button"
          onClick={handleForgotPassword}
          className={`${buttonClass} btn`}
        >
          Enviar código
        </button>
        <button
          onClick={() => setForgotPassword(false)}
          className="forgot_button_user btn_user"
        >
          Volver a inicio de sesión
        </button>
        {error && (
          <div style={{ color: "red" }}>Usuario o contraseña incorrectos</div>
        )}
      </form>
    );
  };

  const renderChangePasswordForm = () => {
    return (
      <form>
        <div className={headerClass}>
          <h1>{formTypeTitle}</h1>
          <h5>Cambiar contraseña</h5>
        </div>
        <div className={groupClass}>
          <label className={labelClass} htmlFor="code">
            Código de verificación
          </label>
          <br />
          <input
            type="text"
            className={inputClass}
            id="code"
            placeholder="Código de verificación"
            value={codeChangePassword}
            onChange={(e) => setCodeChangePassword(e?.target?.value)}
          />
        </div>
        <div className={groupClass}>
          <label className={labelClass} htmlFor="password">
            Nueva contraseña
          </label>
          <br />
          <input
            type="password"
            className={inputClass}
            id="password"
            placeholder="Nueva contraseña"
            value={password}
            onChange={(e) => setPassword(e?.target?.value)}
          />
        </div>
        <div className={groupClass}>
          <label className={labelClass} htmlFor="newPassword">
            Confirmar contraseña
          </label>
          <br />
          <input
            type="password"
            className={inputClass}
            id="newPassword"
            placeholder="Confirmar contraseña"
            value={newPassword}
            onChange={(e) => setNewPassword(e?.target?.value)}
          />
        </div>
        <button
          id="login_button"
          onClick={handleChangePassword}
          className={`${buttonClass} btn`}
        >
          Cambiar contraseña
        </button>
        <button
          onClick={handleForgotPassword}
          className="forgot_button_user btn_user"
        >
          Reenviar código
        </button>
        {error && <div style={{ color: "red" }}>Contraseña incorrecta</div>}
      </form>
    );
  };

  return (
    <Card
      sx={{
        maxWidth: "sm",
        margin: "auto",
        marginTop: "2rem",
        marginBottom: "2rem",
        padding: "2rem",
        backgroundColor: "#fafafa",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e0e0e0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minWidth: 275,
      }}
    >
      <CardContent variant="outlined">
        {forgotPassword
          ? changePassword
            ? renderChangePasswordForm()
            : renderForgotPasswordForm()
          : renderLoginForm()}
      </CardContent>
    </Card>
  );
};

export default LoginShared;
