import React from 'react';
import { ContactPhone } from '@mui/icons-material';
import waIcon from "../../assets/images/whatsapp-icon.svg";
import avatarGestor from "../../assets/images/avatar_ejemplo.png";

const ManagerInfo = ({ adminData, userData }) => {
  const saludo = 'Hola';  

  return (
    <>
      <div className="container-img flex justify-between items-center p-15 bg-blue-600 rounded-t-10 shadow-md">
        <span className="text-white text-2xl font-bold">Información Gestor</span>
        <ContactPhone sx={{ fontSize: 50, color: "white" }} />
      </div>
      {adminData ? (
        <div className="info_gestor">
          <div className="mb-20">
            <img
              className="admin_avatar"
              src={adminData.urlPhoto || avatarGestor}
              alt="Admin Avatar"
            />
            <span className="info_item info_item_title mb-20">
              {adminData.name}
            </span>
          </div>
          <span className="info_item info_item_title font-bold">Horario:</span>
          <span className="info_item mb-20">{adminData.officehours}</span>
          <a
            style={{ fontSize: '16px' }}
            href={`tel:${adminData.phoneNumber}`}
            className="text-base"
          >
            {adminData.phoneNumber}
          </a>
          <a
            style={{ fontSize: '16px' }}
            className="info_item mb-20 text-base"
            href={`mailto:${adminData.mail}?subject=${userData.nickname} - ${userData.fileNum}`}
          >
            {adminData.mail}
          </a>
          <a
            href={`https://wa.me/+34${adminData.phoneNumber}/?text=${saludo} ${adminData.name}`}
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            <button className="wa_button flex items-center justify-center py-2.5 px-14 text-xs uppercase tracking-wide font-bold text-black bg-blue-600 bg-opacity-30 border-none rounded-45 hover:bg-blue-600 hover:bg-opacity-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white">
              CONTÁCTAME
              <img src={waIcon} width="30" height="30" alt="WhatsApp Icon" className="ml-2" />
            </button>
          </a>
        </div>
      ) : (
        <span>No tiene gestor asignado</span>
      )}
    </>
  );
};

export default ManagerInfo;
