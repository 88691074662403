// Hooks ====================================================
import { useState, useEffect } from "react";
// ==========================================================
// Utilidades ===============================================
import customAxiosInstance from "../services/customAxiosInstance";
import debounce from "lodash.debounce";
// ==========================================================

const useData = (setLogged) => {
  // State **************************************************
  const [userData, setUserData] = useState();
  const [adminData, setAdminData] = useState();
  const [isAdmin, setIsAdmin] = useState(null);
  const [isRealState, setIsRealState] = useState(null);
  const [totalAmountCompraventa, setTotalAmountCompraventa] = useState(0);
  const [totalAmountCesion, setTotalAmountCesion] = useState(0);
  //const [managerData, setManagerData] = useState(true)
  const [appFiles, setAppFiles] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const [missingFiles, setMissingFiles] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("cliente");
  // ********************************************************

  //-----------Documents
  // State **************************************************
  const [currentDocument, setCurrentDocument] = useState();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [estadoModalUpload, setEstadoModalUpload] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [openProposalList, setOpenProposalList] = useState(false);
  const [proposalsRealState, setProposalsRealState] = useState([]);
  const [proposalsRealStateFiltered, setProposalsRealStateFiltered] = useState(
    []
  );

  const [states, setStates] = useState([]);

  const [realStateLink, setRealStateLink] = useState("");
  const [realStateLogo, setRealStateLogo] = useState("");
  const [newProposalLink, setNewProposalLink] = useState(""); //TODO  Cambiar a una url real

  // Functions **********************************************
  useEffect(() => {
    if (!firstTime) localStorage.setItem("ch_first_time", false);
  }, [firstTime]);

  useEffect(() => {
    const isLogged = async () => {
      const localStorageFirstTime = localStorage.getItem("ch_first_time");
      if (
        localStorageFirstTime !== undefined &&
        JSON.parse(localStorageFirstTime) === false
      ) {
        setFirstTime(localStorageFirstTime === false);
      }

      const localStorageUserData = localStorage.getItem("chUserData");
      if (localStorageUserData) {
        getProposalStates();
        const userStored = JSON.parse(localStorageUserData);

        userStored?.userData && setUserData(userStored.userData);
        userStored?.adminData && setAdminData(userStored.adminData);

        //COMMON USER
        if (!userStored?.isRealState && !userStored?.isAdmin) {
          await getMissingFiles(userStored?.userData.fileNum);
          await getAppFiles(userStored?.userData.fileNum);
          await getUserData(userStored?.userData.user);
        }

        //ADMIN
        if (userStored.isAdmin) {
          await getProposals(userStored.username);
          setIsAdmin(userStored.isAdmin);
          handleOpenProposalList();
        }

        //REAL STATE
        if (userStored.isRealState) {
          await getProposalsRealState(userStored.userData.realstateId);
          await getProposalStates();
          setIsRealState(userStored.isRealState);
          setRealStateLink(userStored.userData.pdfUrl);
          setRealStateLogo(userStored.userData.logoUrl);
          setNewProposalLink(userStored.adminData.newProposalLink);
        }

        setLogged(true);
      }
    };
    isLogged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProposalStates = async () => {
    const { data } = await customAxiosInstance().get(
      "/database/getStatesOfProposals"
    );
    setStates(data);
  };

  const getUserData = async (user, token) => {
    const { data } = await customAxiosInstance().post(
      `/database/user/${user}`,
      {},
      {
        //cambiar correo
      }
    );
    setUserData(data.userData[0]);
  };

  const getMissingFiles = async (fileNum) => {
    const { data } = await customAxiosInstance().post(
      `/database/${fileNum}/missingFiles`
    );
    setMissingFiles(data.count);
  };

  const getAppFiles = async (fileNum, isRealState = null) => {
    const { data } = await customAxiosInstance().post(
      `/database/appFiles/${fileNum}${isRealState ? '?isRealState=' + isRealState : ''}`
    );
    setAppFiles(data, adminData);
  };

  const getProposals = async (adminUsername) => {
    try {
      const { data } = await customAxiosInstance().post(
        `/database/getProposalsByAdmin`,
        {
          adminUsername,
        }
      );

      /* iterating the array and deleting duplicates by fileNum */
      const uniqueProposals = data.reduce((acc, curr) => {
        if (!acc.some((item) => item.fileNum === curr.fileNum)) {
          acc.push(curr);
        }
        return acc;
      }, []);

      setProposals(uniqueProposals);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSelectProposal = async (fileNum) => {
    const hasProposal = proposals.find(
      (proposal) => proposal.fileNum === fileNum
    );

    if (hasProposal) {
      const { data } = await customAxiosInstance().get(
        `database/getProposalByFileNum/${fileNum}`
      );

      setUserData(data);
      getMissingFiles(data.fileNum);
      getAppFiles(data.fileNum, true);
      handleCloseProposalList();
    }
  };

  const handleCloseProposalList = () => {
    setOpenProposalList(false);
  };

  const handleOpenProposalList = () => {
    setOpenProposalList(true);
  };

  const getProposalsRealState = async (realStateId) => {
    try {

      const response = await customAxiosInstance().get(`database/realstate/${realStateId}`);

      const data = response?.data?.sort((a, b) => {
        const dateA = new Date(a.entryDate.split('/').reverse().join('-'));
        const dateB = new Date(b.entryDate.split('/').reverse().join('-'));
        return dateB - dateA;
      }) || [];

      setProposalsRealState(data);
      setProposalsRealStateFiltered(data);

      let auxTotalCompraventa = 0;
      let auxTotalCesion = 0;

      data.forEach((proposal) => {

        //the format is "200.984,45€" so we need to remove the € and the dots
        const auxReplacedCompraventa = proposal.amountNew ? proposal.amountNew.replace("€", "").replace(".", "") : null;

        auxTotalCompraventa = auxTotalCompraventa + (auxReplacedCompraventa ? parseFloat(auxReplacedCompraventa.replace(",", ".")) : 0);

        const auxReplacedCesion = proposal.percentage ? proposal.percentage.replace("€", "").replace(".", "") : null;

        auxTotalCesion = auxTotalCesion + (auxReplacedCesion ? parseFloat(auxReplacedCesion.replace(",", ".")) : 0);

      }, 0);

      // parse auxTotal to show with euros and 2 decimals
      auxTotalCompraventa = auxTotalCompraventa.toLocaleString("es-ES", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });

      auxTotalCesion = auxTotalCesion.toLocaleString("es-ES", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });


      setTotalAmountCesion(auxTotalCesion);
      setTotalAmountCompraventa(auxTotalCompraventa);

    } catch (err) {
      console.log(err.message);
    }
  };

  const handleFilterProposals = ({ fileNum, initDate, endDate, address, ownerName, collaboratorManager, state, typeDate }) => {
    const filteredProposals = proposalsRealState.filter((proposal) => {
      let proposalMatch = true;

      const isFileNumMatch = !fileNum?.length || proposal.fileNum.toString().includes(fileNum);
      proposalMatch = proposalMatch && isFileNumMatch;

      if (proposalMatch) {
        const isDateInRange = checkIsDateInRange(typeDate, initDate, endDate, proposal);
        proposalMatch = proposalMatch && isDateInRange;
      }

      if (proposalMatch) {
        if (address?.length || ownerName?.length) {
          const proposalOwners = getProposalOwners(proposal);

          const isAddressMatch = checkIsOwnerMatch(address, proposalOwners);
          proposalMatch = proposalMatch && isAddressMatch;

          if (proposalMatch) {
            const isOwnerNameMatch = checkIsOwnerMatch(ownerName, proposalOwners);
            proposalMatch = proposalMatch && isOwnerNameMatch;
          }
        }
      }

      if (proposalMatch) {
        const isCollaboratorManagerMatch = checkIsCollaboratorManagerMatch(collaboratorManager, proposal);
        proposalMatch = proposalMatch && isCollaboratorManagerMatch;

      }

      if (proposalMatch) {
        const isStateMatch = checkIsStateMatch(state, proposal);
        proposalMatch = proposalMatch && isStateMatch;
      }

      return proposalMatch;
    });

    let auxTotalCompraventa = 0;
    let auxTotalCesion = 0;

    filteredProposals.forEach((proposal) => {

      //the format is "200.984,45€" so we need to remove the € and the dots
      const auxReplacedCompraventa = proposal.amountNew ? proposal.amountNew.replace("€", "").replace(".", "") : null;

      auxTotalCompraventa = auxTotalCompraventa + (auxReplacedCompraventa ? parseFloat(auxReplacedCompraventa.replace(",", ".")) : 0);

      const auxReplacedCesion = proposal.percentage ? proposal.percentage.replace("€", "").replace(".", "") : null;

      auxTotalCesion = auxTotalCesion + (auxReplacedCesion ? parseFloat(auxReplacedCesion.replace(",", ".")) : 0);

    }, 0);

    // parse auxTotal to show with euros and 2 decimals
    auxTotalCompraventa = auxTotalCompraventa.toLocaleString("es-ES", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });

    auxTotalCesion = auxTotalCesion.toLocaleString("es-ES", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });

    setTotalAmountCesion(auxTotalCesion);
    setTotalAmountCompraventa(auxTotalCompraventa);
    setProposalsRealStateFiltered(filteredProposals);
  };

  const getProposalOwners = (proposal) => {
    let proposalOwners = []
    if (proposal?.owners?.length) {
      proposalOwners = proposal?.owners;
    } else if (proposal?.owner?.length) {
      proposalOwners = proposal.owner.split('@').map(part => part?.trim());
    }
    return proposalOwners;
  };

  const normalizeText = (text) => {
    if (!text?.length) return text;

    const charMap = {
      // Castellano
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
      'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
      'ü': 'u', 'Ü': 'U',
      'ñ': 'n', 'Ñ': 'N',

      // Catalán
      'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u',
      'À': 'A', 'È': 'E', 'Ì': 'I', 'Ò': 'O', 'Ù': 'U',
      'ç': 'c', 'Ç': 'C',

      // Francés
      'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u',
      'Â': 'A', 'Ê': 'E', 'Î': 'I', 'Ô': 'O', 'Û': 'U',
      'ä': 'a', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'ü': 'u',
      'Ä': 'A', 'Ë': 'E', 'Ï': 'I', 'Ö': 'O', 'Ü': 'U',
      'à': 'a', 'è': 'e', 'ù': 'u',
      'À': 'A', 'È': 'E', 'Ù': 'U',
      'é': 'e', 'É': 'E',
      'ç': 'c', 'Ç': 'C',
      'œ': 'oe', 'Œ': 'OE',
      'æ': 'ae', 'Æ': 'AE'
    };

    return text.replace(/[áéíóúÁÉÍÓÚüÜñÑàèìòùÀÈÌÒÙçÇâêîôûÂÊÎÔÛäëïöÄËÏÖàèùÀÈÙéÉœŒæÆ]/g, function (match) {
      return charMap[match];
    });
  };

  const checkIsDateInRange = (typeDate, initDate, endDate, proposal) => {

    if (initDate == null || endDate == null) return true;

    const evalDate = typeDate == 1 ? proposal?.signDate : proposal?.entryDate;

    if (evalDate == null) return false;

    const day = evalDate.split("/")[0];
    const month = evalDate.split("/")[1];
    const year = evalDate.split("/")[2];

    const entryDate = new Date(`${year}-${month}-${day}`);

    return (entryDate >= initDate || !initDate) && (entryDate <= endDate || !endDate) || (!initDate && !endDate);
  };

  const checkIsOwnerMatch = (ownerInput, proposalOwners) => {
    if (!ownerInput?.length) return true;

    return proposalOwners.some((owner) => normalizeText(owner)?.toLowerCase()?.includes(normalizeText(ownerInput)?.toLowerCase()));
  };

  const checkIsCollaboratorManagerMatch = (collaboratorManager, proposal) => {
    if (!collaboratorManager?.length) return true;

    const collaboratorManagers = Array.isArray(collaboratorManager) ? collaboratorManager : [collaboratorManager];
    return collaboratorManagers.some(manager =>
      normalizeText(proposal.aditionalText)?.toLowerCase()?.includes(normalizeText(manager)?.toLowerCase())
    )
  };

  const checkIsStateMatch = (state, proposal) => {
    if (!state?.length) return true;
    const states = Array.isArray(state) ? state : [state];

    return states.some(st =>
      proposal.currentState?.toLowerCase() === st?.toLowerCase()
    )
  };

  const handleChangeProposalData = async (fileNum, prop, data) => {
    const tempProposals = [...proposalsRealState];

    tempProposals.forEach((proposal) => {
      if (proposal.fileNum === fileNum) {
        proposal[prop] = data;
      }
    });

    setProposalsRealState(tempProposals);

    try {
      await customAxiosInstance().post("database/updateValues", {
        fileNum,
        data,
        value: prop,
      });
    } catch (error) { }
  };

  const handleChangeProposalDataDebounce = debounce(
    async (fileNum, prop, data) => {
      handleChangeProposalData(fileNum, prop, data);
    },
    1000
  );

  // ********************************************************
  return {
    selectedMenu,
    setSelectedMenu,
    appFiles,
    userData,
    adminData,
    setUserData,
    setAdminData,
    getAppFiles,
    firstTime,
    setFirstTime,
    missingFiles,
    setMissingFiles,
    getMissingFiles,
    currentDocument,
    setCurrentDocument,
    filesToUpload,
    setFilesToUpload,
    estadoModalUpload,
    setEstadoModalUpload,
    getUserData,
    handleSelectProposal,
    handleCloseProposalList,
    getProposals,
    openProposalList,
    proposals,
    isAdmin,
    setIsAdmin,
    handleOpenProposalList,
    isRealState,
    setIsRealState,
    proposalsRealState,
    getProposalsRealState,
    handleFilterProposals,
    proposalsRealStateFiltered,
    realStateLink,
    realStateLogo,
    newProposalLink,
    handleChangeProposalData,
    handleChangeProposalDataDebounce,
    states,
    getProposalStates,
    setRealStateLink,
    setRealStateLogo,
    setNewProposalLink,
    totalAmountCompraventa,
    totalAmountCesion
  };
};

export default useData;
