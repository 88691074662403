// Hooks ==========================================================
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
// ================================================================

// Components =====================================================
import Spinner from "../Spinner";
import LoginShared from "../LoginShared";

// ================================================================

// Resources ======================================================
import "./index.css";
import config from "../../config";
// ================================================================

// Utils ==========================================================
import axios from "axios";
// ================================================================

const LoginForm = (props) => {
  // State ***********************************************************
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [codeChangePassword, setCodeChangePassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { setAdminData, setUserData, getAppFiles, setMissingFiles } =
    useContext(AppContext);
  const navigate = useNavigate();
  // *****************************************************************
  useEffect(() => {
    try {
      const token = localStorage.getItem("ch_user_token")
      const chUserDataItem = localStorage.getItem("chUserData")
      const chUserData = chUserDataItem ? JSON.parse(chUserDataItem) : null;
      if (token && chUserData?.userData && chUserData?.adminData) {
        applyLogin(chUserData.userData, chUserData.adminData, token)
      } else {
        localStorage.clear();
      }
    } catch (err) {
      localStorage.clear();
    }
  }, []);

  // Functions *******************************************************
  const handleLogin = async () => {
    try {
      localStorage.removeItem("ch_user_token");
      setLoading(true);
      setError(false);

      const loginRequest = await axios.post(`${config.api}/login`, {
        username,
        password,
        type: "user",
      });

      if (loginRequest?.data?.changePassword && loginRequest?.data?.email) {
        setUsername(loginRequest.data.email);
        setPassword(null);
        setCodeChangePassword(null);
        setNewPassword(null);
        setForgotPassword(true);
        setChangePassword(true);
        setLoading(false);
        return;
      }

      // ----------------------------------------------------------
      // Request to get user data ---------------------------------
      const userDataRequest = await axios.post(
        `${config.api}/database/user/${username}`,
        {},
        {
          //cambiar correo
          headers: {
            Authorization: `Bearer ${loginRequest.data.token}`,
          },
        }
      );
      // ----------------------------------------------------------
      const userData = userDataRequest.data.userData[0];
      const adminData = userDataRequest.data.adminData[0];
      const token = loginRequest.data.token;

      localStorage.setItem("ch_user_token", token);
      localStorage.setItem("chUserData",
        JSON.stringify({
          userData,
          adminData,
        })
      );
      
      await applyLogin(userData, adminData, token);
    } catch (err) {
      setTimeout(() => setError(false), 5000);
      setError(true);
      setLoading(false);
    }
  };


  const applyLogin = async (userData, adminData, token) => {
    setUserData(userData);
    setAdminData(adminData);

    // Request to get MissingFiles --------------------------
    const missingFilesRequest = await axios({
      method: "post",
      url: `${config.api}/database/${userData.fileNum}/missingFiles`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setMissingFiles(missingFilesRequest.data.count);

    // ----------------------------------------------------------
    //-------------------------------------------------------
    setLoading(false);
    props.setLogged(true);
    getAppFiles(userData.fileNum);
    navigate("/");
  };
  // *****************************************************************

  // JSX *************************************************************
  return (
    <>
      <div className="login-form_user">
        {!loading ? (
          <LoginShared
            formTypeTitle="Usuario"
            formTypeSuffixClassName="user"
            labelUsername="Email"
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            codeChangePassword={codeChangePassword}
            setCodeChangePassword={setCodeChangePassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
            changePassword={changePassword}
            setChangePassword={setChangePassword}
            passwordReset={passwordReset}
            setPasswordReset={setPasswordReset}
            loading={loading}
            setLoading={setLoading}
            error={error}
            setError={setError}
            onSubmit={handleLogin}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
  // *****************************************************************
};

export default LoginForm;
