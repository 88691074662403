import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import {
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton
} from "@mui/material";

import { Logout } from "@mui/icons-material";

import CloseIcon from "@mui/icons-material/Close";

const ProposalsList = ({
  title,
  open,
  closable,
  proposals,
  handleSelectProposal,
  handleCloseProposalList,
  handleLogout = () => {},
}) => {
  /*   const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }); */

  const [proposalsFiltered, setProposalsFiltered] = useState(proposals);

  useEffect(() => {
    setProposalsFiltered(proposals);
  }, [proposals]);

  const handleFilterProposals = ({ target }) => {
    const { value } = target;

    const tempProposals = proposals.filter(({ fileNum }) =>
      fileNum.toString().includes(value)
    );

    setProposalsFiltered(tempProposals);
  };

  return (
    <Dialog open={open}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          {closable && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseProposalList()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ m: 2, display: "flex", justifyContent: "center" }}>
        <TextField
          id="outlined-basic"
          label="Nº Propuesta"
          variant="outlined"
          onChange={handleFilterProposals}
        />
      </Box>

      {proposalsFiltered.length > 0 ? (
        <List>
          {proposalsFiltered.map(({ user, fileNum }) => (
            <ListItem
              key={user}
              button
              onClick={() => handleSelectProposal(fileNum)}
            >
              <ListItemText primary={fileNum} secondary={user} />
            </ListItem>
          ))}
        </List>
      ) : (
        <div style={{ display: "flex" }}>
          <Typography variant="h6">No hay propuestas</Typography>
          <button onClick={handleLogout} className="button-back-button">
            <Logout /> Cerrar Sesión
          </button>
        </div>
      )}
    </Dialog>
  );
};

export default ProposalsList;
