import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import './index.css'
export const Row = ({ title, backgroundColor, size, color, deleteButton,handleDeleteButton }) => {
    return (
        <>
            <div className="container_row">
                <div
                    className={`row ${size}`}
                    style={{
                        backgroundColor: backgroundColor,
                        color: color
                    }}>
                    <span className='row-text'>
                        {title}
                    </span>
                    {deleteButton && (
                         <IconButton onClick={handleDeleteButton} aria-label="delete" sx={{color:"white"}} size="large">
                         <Delete />
                       </IconButton>
                    )}

                </div>
            </div>

        </>
    )
}
