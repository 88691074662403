// Hooks ==========================================================
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";

// ================================================================

// Components =====================================================
import Spinner from "../Spinner";
import LoginShared from "../LoginShared";
// ================================================================

// Resources ======================================================
import "./index.css";
import config from "../../config";

// ================================================================

// Utils ==========================================================
import axios from "axios";
// ================================================================

const LoginAdmin = (props) => {
  // State ***********************************************************
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [codeChangePassword, setCodeChangePassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const {
    setAdminData,
    setFirstTime,
    handleOpenProposalList,
    getProposals,
    setIsAdmin,
  } = useContext(AppContext);

  // *****************************************************************
  useEffect(() => {
    try {
      const chUserDataItem = localStorage.getItem("chUserData")
      const chUserData = chUserDataItem ? JSON.parse(chUserDataItem) : null;
      if (chUserData?.isAdmin && chUserData?.username && chUserData?.adminData) {
        applyLogin(chUserData.adminData, chUserData?.username)
      } else {
        localStorage.clear();
      }
    } catch (err) {
      localStorage.clear();
    }
  }, []);

  // Functions *******************************************************
  const handleLogin = async () => {
    try {
      localStorage.removeItem("ch_user_token");
      setLoading(true);
      setError(false);
      const loginRequest = await axios.post(`${config.api}/login`, {
        username,
        password,
        type: "admin",
      });

      if (loginRequest?.data?.changePassword && loginRequest?.data?.email) {
        setUsername(loginRequest.data.email);
        setPassword(null);
        setCodeChangePassword(null);
        setNewPassword(null);
        setForgotPassword(true);
        setChangePassword(true);
        setLoading(false);
        return;
      }

      const adminData = loginRequest.data.adminData;
      const token = loginRequest.data.token;

      localStorage.setItem("ch_user_token", token);
      localStorage.setItem("chUserData",
        JSON.stringify({
          isAdmin: true,
          adminData,
          username,
        })
      );

      applyLogin(adminData, username);
    } catch (err) {
      setTimeout(() => setError(false), 5000);
      setError(true);
      setLoading(false);
    }
  };

  const applyLogin = (adminData, username) => {
    setIsAdmin(true);

    setLoading(false);
    props.setLogged(true);
    setFirstTime(false);
    setAdminData(adminData);

    //getAppFiles(userData.fileNum);
    getProposals(username);
    handleOpenProposalList();
    navigate("/", { replace: true });
  };

  /*   const handleSelectProposalClick = async (fileNum) => {
    handleSelectProposal(fileNum);
    navigate("/", { replace: true });
  }; */

  // *****************************************************************

  // JSX *************************************************************
  return (
    <>
      {/* <ProposalsList
        title="Selecciona una propuesta"
        open={openProposalList}
        closable={false}
        handleSelectProposal={handleSelectProposalClick}
        proposals={proposals}
        handleCloseProposalList={handleCloseProposalList}
      /> */}

      <div className="login-form_admin">
        {!loading ? (
          <LoginShared
            formTypeTitle="Admin CH"
            formTypeSuffixClassName="admin"
            labelUsername="Usuario"
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            codeChangePassword={codeChangePassword}
            setCodeChangePassword={setCodeChangePassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
            changePassword={changePassword}
            setChangePassword={setChangePassword}
            passwordReset={passwordReset}
            setPasswordReset={setPasswordReset}
            loading={loading}
            setLoading={setLoading}
            error={error}
            setError={setError}
            onSubmit={handleLogin}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
  // *****************************************************************
};

export default LoginAdmin;
