import "./index.css";
import { Divider, Stack } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../context/appContext";

const Footer = () => {
    const { isRealState } = useContext(AppContext);
  return (
    <>
      <footer className="footer">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            color: "white",
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          {!isRealState && (
            <>
              {" "}
              <Stack spacing={2}>
                <a
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://centralhipotecaria.com/wp-content/uploads/2021/11/7.-Informacion-precontractual-3.0..pdf"
                >
                  Información pre-contractual
                </a>
                <a
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://centralhipotecaria.com/wp-content/uploads/2021/11/6.-Autorizacion-y-Condiciones-de-Contratacion-3.0..pdf"
                >
                  Condiciones generales de contratación
                </a>
                <a
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://centralhipotecaria.com/wp-content/uploads/2020/12/Normas-transparenciatarifas.pdf"
                >
                  Normas de transparencia y tarifas
                </a>
              </Stack>
              <Divider
                orientation="vertical"
                flexItem
                style={{ margin: "0 15px 0 15px", backgroundColor: "white" }}
              />
            </>
          )}
          <Stack spacing={2}>
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
              href="https://centralhipotecaria.com/pdf/Aviso-legal.pdf"
            >
              Aviso Legal
            </a>
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
              href="https://centralhipotecaria.com/cookies/"
            >
              Política de Cookies
            </a>
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
              href="https://centralhipotecaria.com/pdf/Proteccion-de-datos.pdf"
            >
              Política de Privacidad
            </a>
          </Stack>
        </div>
        <div style={{ textAlign: "center" }}>
          <span style={{ color: "white", fontWeight: "bold" }}>
            Copyright ©{new Date().getFullYear()} Central Hipotecaria
          </span>
        </div>
      </footer>
    </>
  );
};
export default Footer;
