import axios from "axios";
import config from "../config";

export const customAxiosInstance = () => {
  const token = localStorage.getItem("ch_user_token");

  const instance = axios.create({
    baseURL: config.api,
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.location.reload();
        localStorage.removeItem("ch_user_token");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default customAxiosInstance;
