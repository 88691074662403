// App.jsx
import React, { useContext } from "react";
import { AppContext } from "../../context/appContext";
import "./index.css";
import logo from "../../assets/images/logo.png";
import QRCode from "react-qr-code";

const Navbar = () => {
  const { isRealState, realStateLink } = useContext(AppContext);

  return (
    <nav className="navbar">
      <img src={logo} height="48" width="253" alt="logo" />
      {isRealState && realStateLink && (
        <a target="_blank" href={realStateLink} rel="noopener noreferrer">
          <div className="logo-conainer">
 
            <div
              style={{
                background: "white"
              }}
            >
              <QRCode value={realStateLink} size="75" level="L" />
            </div>
            {/*  <img
              className="logo-real-state"
              src={"/qr.svg"}
              height="48"
              width="253"
              alt="logo"
            /> */}
          </div>
        </a>
      )}
    </nav>
  );
};

export default Navbar;
