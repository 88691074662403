// Componentes ====================================================
import LoginForm from "./components/LoginForm";
import Container from "./components/Container";
import Documents from "./components/Documents";
import Dashboard from "./components/Dashboard";
import LoginAdmin from "./components/LoginAdmin";
import LoginRealState from "./components/LoginRealState";
// ================================================================

// Utilidades =====================================================
import { BrowserRouter, Routes, Route } from "react-router-dom";
// ================================================================

// Hooks ==========================================================
import useData from "./hooks/useData";
import { useState } from "react";
// ================================================================

// Context ========================================================
import { AppContext } from "./context/appContext";
import { SelectedDocument } from "./components/SelectedDocument";

// ================================================================

const App = () => {
  //State  **********************************************************
  const [logged, setLogged] = useState(false);
  // ****************************************************************

  // ****************************************************************

  //JSX  ************************************************************
  return (
    <>
      <AppContext.Provider value={useData(setLogged)}>
        <Container logged={logged}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/login"
                element={<LoginForm setLogged={setLogged} />}
              />
              <Route
                path="/Admin"
                element={<LoginAdmin setLogged={setLogged} />}
              />

              <Route
                path="/colaborador"
                element={<LoginRealState setLogged={setLogged} />}
              />

              <Route
                exact
                path="/"
                element={
                  logged ? (
                    <Dashboard setLogged={setLogged} />
                  ) : (
                    <LoginForm setLogged={setLogged} />
                  )
                }
              />

              <Route
                exact
                path="/proposal/:fileNum"
                element={
                  logged ? (
                    <Dashboard setLogged={setLogged} />
                  ) : (
                    <LoginForm setLogged={setLogged} />
                  )
                }
              />

              <Route
                exact
                path="/Documents"
                element={
                  logged ? <Documents /> : <LoginForm setLogged={setLogged} />
                }
              />

              <Route
                exact
                path="/SelectedDocument"
                element={
                  logged ? (
                    <SelectedDocument />
                  ) : (
                    <LoginForm setLogged={setLogged} />
                  )
                }
              />

              <Route path="*" element={<h1>No encontrado</h1>} />
            </Routes>
          </BrowserRouter>
        </Container>
      </AppContext.Provider>
    </>
  );
};

export default App;
